
// https://sass-lang.com/guide

$font-stack:    Helvetica, sans-serif;
$w: white;
$c1: #00b7b7;
$c2: #ffb7be;;
$c3: #137ce5;
$c4: #13e57c;
$c5: #e5e513;
$c6: #696969;
$c7: rgba(144, 144, 144, 0.25);
$fb: #3578E5;

html {
  font-size: 2em !important;
}

header, .navbar, footer {
  background-color: $c1 !important;
}

.navbar {
  .ant-menu-item {
     &:hover {
       color: $w !important;
       border-bottom: none !important;
     }
  }

}
.ant-layout-header {
  color: $c6 !important;
  line-height: 50px !important;
  padding: 0 2em !important;
  font-weight: 400;
}

main {
  padding: 0 2em !important;
  background-color: $w;

  .anticon {
    background: $c1;
    padding: 0.4em;
    border-radius: 50px;
  }
}

.logo {
  float: left;
}

.resume,.ant-modal-close-x {
  &:hover {
    color: $c1;
    cursor: pointer;
  }
}

.ant-list-item-meta-avatar {
  margin-top: 8px;
}

.ant-list-bordered {
  border: 0 !important;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  &:hover {
    color: $c6;
  }
}

.link {
  color: $c1;
  font-weight: bolder;

  &:hover {
    color: $c2;
  }
}

.ant-menu-item > span, .ant-menu-item > svg {
  &:hover {
    color: $c6;
  }
}
.story {
  margin-top: 0.5em;
  margin-bottom: 2em;
  text-align: center;

  .col-card {
    padding-bottom: 8px;
    padding-top: 8px;

    .ant-card {
      margin: auto !important;

      .ant-card-cover {
        .ant-card-meta {
          text-align: center;
          .ant-card-meta-title{
            color : $c1;
          }
        }

        .rounded  {
          img {
            width: 20em !important ;
            height: 20em !important;
            border-radius: 50% !important;
            border: solid 0.5em $c1 !important;
            margin-top: 2em;
          }
        }

        h2 {
          margin-top: 3em;
          color : $c1;
        }

        .description {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        div {
          text-align: -webkit-center;
          .btn-description {
            width: 100px;
            border-radius: 16px;
            background-color: $c1;
          }
        }
      }
      .ant-divider {
        color: $c1;
      }
    }
  }
}

.contact {
  margin-top: 3em;
  margin-bottom: 3em;

  .title {
    text-transform: uppercase;
  }

  .icon {
      margin-bottom: 10px;
  }
 
  .ant-row{
    place-content: center !important;

  .ant-col {
      text-align: center;

      a {
        color :black;
        text-decoration: none;
        display: block;
        margin: 5px;
        p {
          color : $c6;
          display: inline;
        }
      }
      .anticon {
      padding: 5px;
      margin-right: 5px;
      
      &.anticon-facebook {
        background-color: $fb;
      }

      &.anticon-instagram {
        background: #f09433; 
        background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
        background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
      }
    }
    }
  }

  form {
    margin-top: 2em;
    margin-bottom: 4em;
    border: 4px solid $c1;
    border-radius: 16px;

    .title {
      margin-top: 2em;
    }

    div {
      justify-content: center;
    }

    h1, .ant-row {
      text-align: center;
    }

    input, textarea {
      border-radius: 8px;
    }

    .contact-button {
      border-radius: 16px;
      background-color: $c1;

      &:hover {
        border-color: $w;
        color: $w;
      }
    }
  }

}

.bio {
  .ant-row{
    justify-content: center;
  }

  .img-div {
    text-align: center;
    img {
      border-radius: 16px;
      margin-bottom: 3em;
   }
  }
}

.faq {
  margin-top: 0.5em;
  margin-bottom: 2em;
  text-align: center;

  .col-card {
    padding-bottom: 8px;
    padding-top: 8px;

    .ant-card {
      .ant-card-cover {
          .ant-row {
            justify-content: center;
            .ant-col {
              margin: 20px;
            }
          }

        div {
          text-align: -webkit-center;
          .btn-description {
            width: 100px;
            border-radius: 16px;
            background-color: $c1;
          }
        }
      }
    }
  }
}


.prices, .bio, .story {
  h1 {
    margin: 0.6em 0;
  }

  margin-bottom: 4em;
}


.prices {
  text-align: center;

  .ant-row{
    justify-content: center;

    .ant-col {
      height: 100% ;

      .ant-list-item {
        height: 100%;
        margin-top: 2em;
        .ant-card-bordered {
          border-radius: 16px;
          height: 90% ;

          p {
              margin: auto;
              color: $c1;
          }

          .rdv {
            color: $c6;
          }

          .detail {
            margin-bottom: 2em;
          }

          .price {
            background-color: $c1;
            color: $w;
            border-radius: 16px;
            width: 40%;
            margin: 1em auto;
          }
          
        }
      }
    }
  }



  .ant-card-head {
    border-bottom: 2px solid $c1;
    .ant-card-head-title {
      span {
        margin-left: 10px;
      }      
    }
  }
  
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1em 0 !important;
  width: 100%;

  .facebook-link {
    .anticon {
      background-color: $fb;
      padding: 5px;
      border-radius: 50px;
    }
  }
}

.ant-menu-submenu-popup  {
  right: 0;
}